<!-- 自营外协司机编辑弹窗 -->
<template>
  <el-dialog
    width="40%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'商品维护':'商品维护'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px">

      <el-form-item label="服务项目" label-width="100px" prop="affiliation_type">
        <el-select
          clearable
          v-model="form.affiliation_type"
          placeholder="请选择"
          class="ele-fluid">
          <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
        </el-select>
      </el-form-item>
      <el-form-item label="名称:" prop="name">
        <el-input
          clearable
          v-model="form.name"
          placeholder="请输入名称"/>
      </el-form-item>
      <el-form-item label="次数:" prop="amount">
        <el-input
          clearable
          v-model="form.amount"
          placeholder="请输入次数"/>
      </el-form-item>
      <el-form-item label="金额:" prop="pay_price">
        <el-input
          clearable
          v-model="form.pay_price"
          placeholder="请输入金额"/>
      </el-form-item>
      <el-form-item label="有效期:" prop="vip_day">
        <el-select
          clearable
          v-model="form.vip_day"
          placeholder="请选择"
          class="ele-fluid">
          <el-option
            v-for="(item) in dict.type.validity"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="状态:" prop="status">
        <el-switch v-model="form.status" :active-value="0" :inactive-value="1"></el-switch>
      </el-form-item>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">提交
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
// 引入的接口
//服务项目接口
import {getget_services_available} from '@/api/yunli'
import {Addmall_package, Editmall_package} from "@/api/operate";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        affiliation_type:[
          { required: true, message: '请选择', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入次数', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        pay_price: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        vip_day:[
          { required: true, message: '请选择有效期', trigger: 'change' },
        ],
        status:[
          { required: true, message: '请选择状态', trigger: 'change' },
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //服务项目
      ServicesAvailable:[],

    };
  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    //获取服务项目
    this.getFuwuxiangmu();
  },
  methods: {
    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            Addmall_package(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            Editmall_package(data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },


    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
.sijilist{
  .sijilistactive{
    cursor: pointer;
    display: inline-block;
    width: 64px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    color: #868792;
    margin: 10px;
  }
  .sijilistactive1{
    color: #FF9B05;
    border-bottom: 4px solid #FF9B05;
  }
}

.sijiradio{

}
.listactive{
  width: 100%;
  border: 1px solid #ebecf2;
  border-radius: 6px;
  padding: 20px;
  margin-top: 15px;
}
.listactive1{
  border: 1px solid #fea837;
}
.diyixiang:first-child{
  margin-top: 0 !important;
}

</style>
